import React, { useRef, useEffect } from "react";
import "./About.scss";
import AOS from "aos";
import "aos/dist/aos.css";
import FullHeightSection from "../../components/fullHeightSection/FullHeightSection";
import TwoCol from "../../components/twocol/TwoCol";
import Doctors from "../../components/doctors/Doctors";
import image from "../../assets/boy.webp";
import reva from "../../assets/revolution_about.webp";
import globa from "../../assets/global_about.webp";
import esha from "../../assets/about/Esha_lokapur.webp";
import abhinav from "../../assets/about/abhinav_tiwary.webp";
import chetna from "../../assets/about/chetna_gargi.webp";
import Meenal from "../../assets/about/Meenal_Mehendale.webp";
import shweta from "../../assets/about/Shweta_Anand.webp";
import damanjeet from "../../assets/about/damanjeet.jpeg";
import utkarsha from "../../assets/about/Utarsha.webp";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

function About() {
  const data1 = [
    {
      heading: "Care Anytime, Anywhere, In Any Language",
      
      content: ` AxonCare, a service brand by Axonic, connects doctors and patients globally, offering health access anytime, anywhere, in any language. Our specialists and innovative solutions, including the "Anytime Health kiosk" and "AxonCare" app, ensure personalized care without barriers.`,
    },
  ];

  const data2 = [
    {
      heading: "Redefining All Possibilities in Healthcare",
      content:
        "Axonic, a US-based organization with Indian roots, is transforming healthcare delivery through a patient-centric approach. By integrating technology with human expertise, we bridge geographical gaps, making healthcare accessible, affordable, and available to everyone, regardless of country or language.",
    },
  ];

  const doctors_data = [
    {
      image: shweta,
      heading: "Dr. Shweta Anand",
      description: "Clinical Psychologist",
      designation: "Mental Health Counselor",
    },
    {
      image: abhinav,
      heading: "Dr. Abhinav Tewari",
      description: "MBBS MD Psychiatry",
      designation: "Psychiatrist",
    },
    {
      image: Meenal,
      heading: "Dr. Meenal Mehendale",
      description: "MBBS MD OB GYN",
      designation: "Gynaecologist",
    },
    {
      image: chetna,
      heading: "Dr. Chetna Gargi",
      description: "MBBS Diploma in Diabetes",
      designation: "Diabetologist",
    },
    {
      image: esha,
      heading: "Dr. Esha Lokapur",
      description: "MBBS, DNB (Gen. Med.) ",
      designation: "Physician",
    },
    {
      image: damanjeet,
      heading: "Dr. Damanjeet Duggal",
      description: " MBBS, MD (Resp. Med), ISCCM",
      designation: "Cardiologist, Pulmonologist, Diabetologist",
    },
    {
      image: utkarsha,
      heading: "Dr. Utkarsha Somani",
      description: " PG Diploma in Dietetics",
      designation: "Dietician",
    },
  ];

  useEffect(() => {
    // Initialize AOS
    AOS.init({
      duration: 1000, // Animation duration
      easing: "ease-out-quart", // Animation easing
    });
  }, []);

  return (
    <>
      <Helmet>
        {/* Meta Title */}
        <title>
          AxonCare: Global Healthcare Anytime, Anywhere, in Any Language
        </title>

        {/* Meta Description */}
        <meta
          name="description"
          content="AxonCare, by Axonic, connects doctors and patients globally. Offering health access anytime, anywhere, in any language, our innovative solutions, such as the 'Anytime Health Kiosk' and 'AxonCare' app, ensure personalized care without barriers."
        />

        {/* Meta Keywords */}
        <meta
          name="keywords"
          content="AxonCare, Axonic, global healthcare, healthcare app, multilingual healthcare, healthcare access, AI-powered assistance, real-time health insights, worldwide healthcare, specialist consultations, medical history management, personalized care"
        />
      </Helmet>
      {/* <FullHeightSection data={data1} background="white" /> */}

      <div
        className="parent about-parent bg-img-cover"
        data-aos="fade-up"
        data-aos-delay="500"
        data-aos-duration="1000"
      >
        <div className="overlay-about"></div>
        <div className="container about-container">
          <h2 data-aos="fade-up" data-aos-delay="500" data-aos-duration="1000">
            Care <span>Anytime,</span> <span>Anywhere,</span> In{" "}
            <span>Any Language</span>
          </h2>
          <p data-aos="fade-up" data-aos-delay="700" data-aos-duration="1000">
            AxonCare, a service brand by Axonic, connects doctors and patients
            globally, offering health access anytime, anywhere, in any language.
            Our specialists and innovative solutions, including the "Anytime
            Health Kiosk" and "AxonCare" app, ensure personalised care without
            barriers.
          </p>
          <a
            href="https://axonichealth.com/"
            data-aos="fade-up"
            data-aos-delay="900"
            data-aos-duration="1000"
            target="_blank"
          >
            <button className="cta-btn cat-btn">Know More</button>
          </a>
        </div>
      </div>

      <div
        className="parent about-parent1"
        data-aos="fade-up"
        data-aos-delay="500"
        data-aos-duration="1000"
      >
        <div className="container about-container1">
          <h2 data-aos="fade-up" data-aos-duration="1000 " data-aos-delay="500">
            <span>Redefining</span> All Possibilities in Healthcare
          </h2>
          <p data-aos="fade-up" data-aos-delay="700" data-aos-duration="1000">
            Axonic, a US-based organisation with Indian roots, is transforming
            healthcare delivery through a patient-centric approach. By
            integrating technology with human expertise, we bridge geographical
            gaps, making healthcare accessible, affordable, and available to
            everyone, regardless of country or language.
          </p>
          <a
            href="https://axonichealth.com/"
            target="_blank"
            data-aos="fade-up"
            data-aos-delay="900"
            data-aos-duration="1000"
          >
            <button className="cta-btn cat-btn">Know More</button>
          </a>
        </div>
      </div>

      {/* <FullHeightSection data={data2} background="var(--grey)" /> */}
      <TwoCol
        reverse={false}
        background={false}
        span="Revolutionising "
        title="Healthcare Delivery with AxonCare Experience"
        description="At AxonCare, we’re transforming healthcare delivery through innovative technologies designed to make healthcare accessible and affordable for everyone. Our cutting-edge solutions enhance care while adding a human touch, ensuring a near-human experience for all patients."
        image={reva}
        checkboxes={[
          "Multilingual Support",
          "Comprehensive Medical History",
          "AI-Powered Assistance",
          "Real-Time Health Insights",
        ]}
      />

      <Doctors data={doctors_data} background="var(--grey)" />

      <TwoCol
        reverse={true}
        background={false}
        title="AxonCare Expanding"
        span3="Global"
        span2=" Healthcare Horizons"
        description="AxonCare operates as a global network, bridging healthcare professionals and patients across the world. Whether you’re in India seeking a consultation with a specialist in the UK, our platform enables seamless communication at your convenience and in your preferred language. This revolutionary service breaks down barriers, providing care to individuals worldwide."
        image={globa}
        checkboxes={[
          "Worldwide Accessibility",
          "Convenient Consultations",
          "Language Flexibility",
          "Expanding Network",
        ]}
      />
    </>
  );
}

export default About;
