import React, { useState } from "react";
import "./contactform.scss";
import { Modal, Button } from "antd";

const ContactForm = ({ heading, description, reusing, close }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  const showModal = (message) => {
    setModalMessage(message);
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  function Submit(e) {
    e.preventDefault();

    const formEle = document.querySelector("form");
    const formDatab = new FormData(formEle);

    fetch(
      "https://script.google.com/macros/s/AKfycbycSWCwTWltFPCVaR1beWy8rexdGcjIvcxzGaQLb4BnLijEVqlNmo8bXDe8wFKnxveP_w/exec",
      {
        method: "POST",
        body: formDatab,
      }
    )
      .then((res) => res.text())
      .then((data) => {
        console.log(data);
        showModal("Your message was sent successfully!");
        formEle.reset();
      })
      .catch((error) => {
        console.error("Error:", error);
        showModal("Something went wrong. Please try again.");
      });
  }

  return (
    <>
      <div
        className="form-container"
        style={{
          boxShadow: reusing ? "0px 0px 0px" : "",
          padding: reusing ? "0px" : "",
        }}
      >
        <h2 className="heading contact-form-heading">{heading}</h2>
        <p className="contact-form-description">{description}</p>
        <form className="contact-form" onSubmit={Submit}>
          <div className="form-group">
            <input type="text" id="name" required name="name" />
            <label htmlFor="name">Name *</label>
          </div>
          <div className="form-group">
            <input type="number" id="phone" required name="phone" />
            <label htmlFor="phone">Phone *</label>
          </div>
          <div className="form-group">
            <input type="email" id="email" required name="email" />
            <label htmlFor="email">Email *</label>
          </div>
          <div className="form-group">
            <textarea id="message" rows="4" name="message" />
            <label htmlFor="message">Message</label>
          </div>
          <div className="button-box">
            <button type="submit" className="cta-btn">
              Send Message
            </button>
          </div>
        </form>
      </div>

      <Modal
        title="Notification"
        open={isModalVisible}
        onOk={handleOk}
        footer={null}
      >
        <p>{modalMessage}</p>
        <div style={{ textAlign: "right" }}>
          <button className="cta-btn" onClick={handleOk}>
            OK
          </button>
        </div>
      </Modal>
    </>
  );
};

export default ContactForm;
